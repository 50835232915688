import moment from 'moment';

export const COLUMNS_COUNT_ORCADO = [
  {
    Header: "Quantidade",
    accessor: "qtde",
  },
  {
    Header: "Data de início",
    accessor: "dataInicial",
    Cell: ({ value }) => {
        return moment(value).format("DD/MM/YYYY");
    },
    sortType: "datetime",
   
  },
  {
    Header: "Data de fim",
    accessor: "dataFinal",
    Cell: ({ value }) => {
        return moment(value).format("DD/MM/YYYY");
    },
    sortType: "datetime",
  },
];
export const COLUMNS = [
  {
    Header: "Descrição",
    accessor: "descricao",
  },
];
