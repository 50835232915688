export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao"
    },
    {
        Header: "Visão por hierarquia?",
        accessor: "hierarquico",
        Cell: ({ value }) => (value ? "Sim" : "Não")
    }
]
