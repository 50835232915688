import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const notifySuccess = (text) => {
  Toast.fire({
    icon: "success",
    title: text,
    background: "#2ECC40",
    color: "white",
    iconColor: "white",
  });
};

export const notifyInfo = (text) => {
  Toast.fire({
    icon: "info",
    title: text,
  });
};

export const notifyInfoPassword = (text) => {
  Toast.fire({
    icon: "info",
    title: text,
    position: "top-right",
  });
};

export const notifyWarn = (text) => {
  Toast.fire({
    icon: "warning",
    title: text,
    background: "#FF851B",
    color: "white",
    iconColor: "white",
  });
};

export const notifyError = (text) => {
  Toast.fire({
    icon: "error",
    title: text,
    background: "#FF4136",
    color: "white",
    iconColor: "white",
  });
};
