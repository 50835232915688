import React from 'react'
import { Button, Card, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { useSelector } from 'react-redux'
import * as actions from '../../../store/modules/campanhaPremiacao/campanha/actions'
import { Select } from '../../../components/Inputs/Input'
import { dias, diasSemana } from '../constants/dias'
import * as controller from '../../../controllers/premiacaoController/campanhaPremiacao'
import { maskMonthYear, removeAccentsAndUppercase } from '../../../util/masks'

import classnames from 'classnames';
import { RegraQualidade } from '../components/RegraQualidade'
import { useEffect } from 'react'
import { RegraProdutividade } from '../components/RegraProdutividade'

const Register = () => {

    const { item, copy, invalid, seeRegister, tiposOperacao, canais,tiposPendencia, periodos, tiposParceria, convenios, bancos, centrosCusto, produtos,periodicidades, tab } = useSelector(state => state.campanhaPremiacao)

    function onChangeItem(value, field) {
        actions.cleanInvalid(field)
        actions.setItem(field,value)
    }

    function changeTab(value){
        actions.setTab(value)
    }

    async function saveItem() {
        actions.cleanInvalids()
        if (item.id === '0') {
            const isSuccess = controller.create(item)
            if(isSuccess === false){
                return;
            }
        } else {
            const isSuccess = await controller.update(item, copy);
            if(isSuccess === false){
                return;
            }

            let content = await controller.getById(item.id);
            let campanhaPremiacaoRegras = []
            content.campanhaPremiacaoRegras.forEach((regra)=>{
            let instituição = bancos.find((banco) => banco.id === regra.bancoId) ?? ''
            let convenio = convenios.find((convenio) => convenio.id === regra.convenioId) ?? '' 
            let tipoOperacao = tiposOperacao.find((operacao) => operacao.id === regra.tipoOperacaoId) ?? '' 
            let tipoParceria = tiposParceria.find((parceria) => parceria.id === regra.tipoParceriaId) ?? '' 
            let produto = produtos.find((produto) => produto.id === regra.produtoId) ?? '' 
            let centroCusto = centrosCusto.find((centroCusto) => centroCusto.id === regra.centroCustoId) ?? '' 

            campanhaPremiacaoRegras.push({
                ...regra,
                instituição : instituição.nome,
                convenio: convenio.descricao,
                tipoOperacao : tipoOperacao.descricao,
                tipoParceria: tipoParceria.nome,
                produto: produto.nome,
                centroCusto: centroCusto.descricao
            })
        })
        actions.setItem('campanhaPremiacaoRegras', campanhaPremiacaoRegras)

        let campanhaPremiacaoRegraQualidade = []
        content.campanhaPremiacaoRegraQualidade.forEach((regra)=>{
            let tipoPendencia = tiposPendencia.find((tipoPendencia) => tipoPendencia.id === regra.tipoPendenciaId) ?? ''
            let periodo = periodos.find((periodo) => periodo.id === regra.periodoId) ?? '' 

            campanhaPremiacaoRegraQualidade.push({
                ...regra,
                tipoPendencia: tipoPendencia.descricao,
                periodo: periodo.descricao,
            })
        })
        actions.setItem('campanhaPremiacaoRegraQualidade', campanhaPremiacaoRegraQualidade)

        let diaPagamento = periodicidades.find((periodo) => periodo.id === item.periodicidadePagamentoId)
        let diaApuracao = periodicidades.find((periodo) => periodo.id === item.periodicidadeApuracaoId)

        actions.setItem('descricaoLabel', diaPagamento.descricao)
        actions.setItem('descricaoLabelApuracao', diaApuracao.descricao)
        }
    }

    useEffect(()=>{
        controller.getTiposPendencia();
        controller.getPeriodos();
        controller.getBancos();
        controller.getConvenios();
        controller.getTiposOperacao();
        controller.getCanais();
        controller.getGrupos();
        controller.getTiposParceria();
        controller.getProdutos();
        controller.getTiposComissao();
    }, [])
    
    useEffect(()=>{
        if(item.id !== '0'){
            let diaPagamento = periodicidades.find((periodo) => periodo.id === item.periodicidadePagamentoId)
            let diaApuracao = periodicidades.find((periodo) => periodo.id === item.periodicidadeApuracaoId)

            actions.setItem('descricaoLabel', diaPagamento.descricao)
            actions.setItem('descricaoLabelApuracao', diaApuracao.descricao)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.id])

    return (
        <>
            <Col>
                <Card className='radius pb-5' style={{ display: 'flex' }}>
                    <Row>
                        <Col className='pt-2' style={{ margin: '25px 0 0 25px' }}>
                            <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>
                                Preencha os campos para cadastrar uma nova Campanha de Premiação
                            </p>
                        </Col>
                        {!seeRegister && (
                            <Col className='mt-4'>
                                <Button
                                    onClick={() => saveItem()}
                                    style={{ width: '150px', float: 'right' }}
                                    className='purpleButton mr-5'
                                >
                                    Salvar
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row className='pr-5 pl-5 mt-3'>
                        <Col md={5} >
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='descricaoCadastro'>Descrição da Campanha de Premiação <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                            <FormGroup>
                                <Input
                                    type='text'
                                    id='descricaoCadastro'
                                    placeholder='Digite aqui...'
                                    value={item.descricao}
                                    onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), 'descricao')}
                                    className={invalid.descricao ? 'is-invalid' : ''}
                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                    maxLength='60'
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='pr-5 pl-5 mt-3'>
                        <Col md={2}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='apuracao'>Apuração <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                <Select
                                    first='SELECIONE'
                                    value={{ value: item.periodicidadeApuracaoId }}
                                    select={{ value: 'id', label: 'descricao' }}
                                    options={[
                                        {
                                        "id": "bfc0c31d-72bb-44ff-b3f2-487bde7a29c3",
                                        "descricao": "MENSAL",
                                        "periodo": false
                                        },
                                        {
                                        "id": "15115c0b-99ac-4344-af0c-b8e2dca42f68",
                                        "descricao": "SEMANAL",
                                        "periodo": false
                                        }
                                    ]}
                                    onChange={e => {
                                        onChangeItem(e.value, 'periodicidadeApuracaoId');
                                        onChangeItem(e.label, 'descricaoLabelApuracao');
                                        onChangeItem('0', 'periodicidadePagamentoId');
                                        onChangeItem('', 'descricaoLabel');
                                        onChangeItem('0', 'diaApuracao');
                                        onChangeItem('', 'periodo');
                                        onChangeItem('', 'periodoInicio');
                                        onChangeItem('', 'periodoFim');
                                        if(e.label === 'MENSAL') {
                                            onChangeItem(periodicidades.find((periodicidade) => periodicidade.descricao === "MENSAL").id, 'periodicidadePagamentoId')
                                        }
                                    }}
                                    isSearchable={true}
                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                    autoComplete='off'
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: invalid.periodicidadeApuracaoId ? 'red' : 'lightgrey'
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {
                            item.descricaoLabelApuracao === 'SEMANAL' ? (
                                <>
                                    <Col md={2}>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} >Período Início <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                        <FormGroup>
                                            <Input
                                                type='date'
                                                placeholder='dd/mm/aaaa'
                                                value={item.periodoInicio}
                                                onChange={e => onChangeItem(e.target.value, 'periodoInicio')}
                                                className={invalid.periodoInicio ? 'is-invalid' : ''}
                                                disabled={item.usuarioAprovadorId ? true : seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} >Período Fim <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                        <FormGroup>
                                            <Input
                                                type='date'
                                                placeholder='dd/mm/aaaa'
                                                value={item.periodoFim}
                                                onChange={e => onChangeItem(e.target.value, 'periodoFim')}
                                                className={invalid.periodoFim ? 'is-invalid' : ''}
                                                disabled={item.usuarioAprovadorId ? true : seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col md={2}>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} >Período <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                        <FormGroup>
                                            <Input
                                                type='text'
                                                placeholder='mm/aaaa'
                                                value={item.periodo}
                                                onChange={e => onChangeItem(maskMonthYear(e.target.value), 'periodo')}
                                                className={invalid.periodo ? 'is-invalid' : ''}
                                                disabled={item.usuarioAprovadorId ? true : seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )
                        }
                        {
                            item.periodicidadeApuracaoId !== '0' ?
                                item.descricaoLabelApuracao &&
                                    item.descricaoLabelApuracao !== 'DIÁRIO' ?
                                    (item.descricaoLabelApuracao === 'MENSAL' || item.descricaoLabelApuracao === 'FINAL DA CAMPANHA') ?

                                        <Col md={2}>
                                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='diaPagamento'>Dia da Apuração <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                            <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item.diaApuracao }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={dias}
                                                    onChange={e => onChangeItem(e.value, 'diaApuracao')}
                                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                                    isSearchable={true}
                                                    autoComplete='off'
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: invalid.diaApuracao ? 'red' : 'lightgrey',
                                                            height: '33.5px',
                                                            minHeight: '33.5px'
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        :
                                        <Col md={2}>
                                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='diaPagamento'>Dia da Apuração <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                            <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item.diaApuracao }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={diasSemana}
                                                    onChange={e => {onChangeItem(e.value, 'diaApuracao');}}
                                                    isSearchable={true}
                                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                                    autoComplete='off'
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: invalid.diaApuracao ? 'red' : 'lightgrey',
                                                            height: '33.5px',
                                                            minHeight: '33.5px'
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    :
                                    ''
                                :
                                ''
                        }
                        <Col md={2}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Pagamento <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                <Select
                                    first='SELECIONE'
                                    value={{ value: item.periodicidadePagamentoId }}
                                    select={{ value: 'id', label: 'descricao' }}
                                    options={!item.descricaoLabelApuracao ? [] : item.descricaoLabelApuracao === 'MENSAL'  ?  periodicidades.filter(item=> item.descricao === 'MENSAL') : item.descricaoLabelApuracao === 'FINAL DA CAMPANHA' ?  periodicidades.filter(item=> item.descricao === 'FINAL DA CAMPANHA') : item.descricaoLabelApuracao === 'SEMANAL' ? periodicidades.filter(item=> item.descricao === 'MENSAL' || item.descricao === 'SEMANAL') : periodicidades}
                                    onChange={e => {
                                        onChangeItem(e.value, 'periodicidadePagamentoId')
                                        onChangeItem(e.label, 'descricaoLabel');
                                        onChangeItem('0', 'diaPagamento');
                                    }}
                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                    isSearchable={true}
                                    autoComplete='off'
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: invalid.periodicidadePagamentoId ? 'red' : 'lightgrey'
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {
                            item.periodicidadePagamentoId !== '0' ?
                                item.descricaoLabel !== 'DIÁRIO' ?
                                    (item.descricaoLabel === 'MENSAL' || item.descricaoLabel === 'FINAL DA CAMPANHA') ?

                                        <Col md={2}>
                                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='diaPagamento'>Dia do Pagamento <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                            <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item.diaPagamento }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={dias}
                                                    onChange={e => onChangeItem(e.value, 'diaPagamento')}
                                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                                    isSearchable={true}
                                                    autoComplete='off'
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: invalid.diaPagamento ? 'red' : 'lightgrey',
                                                            height: '33.5px',
                                                            minHeight: '33.5px'
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        :
                                        <Col md={2}>
                                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for='diaPagamento'>Dia do Pagamento <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                            <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item.diaPagamento }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={diasSemana}
                                                    onChange={e => onChangeItem(e.value, 'diaPagamento')}
                                                    isSearchable={true}
                                                    disabled={item.usuarioAprovadorId ? true : seeRegister}
                                                    autoComplete='off'
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: invalid.diaPagamento ? 'red' : 'lightgrey',
                                                            height: '33.5px',
                                                            minHeight: '33.5px'
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    :
                                    ''
                                :
                                ''
                        }
                         <Col md={3}>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Tipo funcionário <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                                <FormGroup>
                                    <Select
                                        first='SELECIONE'
                                        value={{ value: item.tipoFuncionarioId }}
                                        select={{ value: 'id', label: 'nome' }}
                                        options={canais}
                                        onChange={e => onChangeItem(e.value, 'tipoFuncionarioId')}
                                        disabled={item.usuarioAprovadorId ? true : seeRegister}
                                        isSearchable={true}
                                        autoComplete='off'
                                    />
                                </FormGroup>
                            </Col>
                        <Col md={3} >
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Cód. Superação</Label>
                            <FormGroup>
                                <Input
                                    type='text'
                                    placeholder='Digite aqui...'
                                    value={item.codigoSuperacao}
                                    onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), 'codigoSuperacao')}
                                    className={invalid.codigoSuperacao ? 'is-invalid' : ''}
                                    disabled={seeRegister}
                                    maxLength='60'
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Cód. Produtividade</Label>
                            <FormGroup>
                                <Input
                                    type='text'
                                    placeholder='Digite aqui...'
                                    value={item.codigoProdutividade}
                                    onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), 'codigoProdutividade')}
                                    className={invalid.codigoProdutividade ? 'is-invalid' : ''}
                                    disabled={seeRegister}
                                    maxLength='60'
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Cód. Regra Repasse</Label>
                            <FormGroup>
                                <Input
                                    type='text'
                                    placeholder='Digite aqui...'
                                    value={item.codigoRepasse}
                                    onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), 'codigoRepasse')}
                                    className={invalid.codigoRepasse ? 'is-invalid' : ''}
                                    disabled={seeRegister}
                                    maxLength='60'
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    { item.id !== '0' && (
                        <Card className="pt-3 pb-2 m-2">
                        <div>
                            <Nav style={{ marginLeft: '25px', marginBottom: '30px' }}>
                                <NavItem style={{ padding: '0 5px 0 5px' }}>
                                    <NavLink
                                        className={classnames({ active: tab === '1' })}
                                        onClick={() => { changeTab('1'); }}
                                        style={
                                            tab === '1' ?
                                                { color: 'var(--color-preto', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                                : { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer' }}
                                    >
                                        
                                            Regra Qualidade
                                    </NavLink>       
                                </NavItem>        
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: tab === '2' })}
                                        onClick={() => { changeTab('2'); }}
                                        style={
                                            tab === '2' ?
                                                { color: 'var(--color-preto', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                                : { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer' }}
                                    >
                                        Regra Produção
                                    </NavLink>  
                                </NavItem>                                                                                                      
                            </Nav>
                            <TabContent activeTab={tab}>
                                <TabPane tabId="1">
                                    <RegraQualidade/>
                                </TabPane>
                                <TabPane tabId="2">
                                    <RegraProdutividade/>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Card>
                    )}
                </Card>
            </Col>
        </>
    )
}

export default Register