import React, { useState } from 'react';
import { CgLock, CgLockUnlock } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, Row, Navbar, Col, Form, FormGroup, Label } from 'reactstrap';
import { InputPassword } from "../../Inputs/Input";
import * as actions from "../../../store/modules/alterarSenha/actions";
import * as controller from "../../../controllers/alterarSenhaController";

const ModalSenha = ({ isOpen, toggle }) => {

    const { password, invalid, validPassword } = useSelector(state => state.alterarSenha);
    const [validatePassword, setValidate] = useState(false);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            controller.update(password, validPassword)
        }
    }

    function onChange(value, field) {
        actions.cleanInvalid(field);
        actions.password({ ...password, [field]: value });
    }

    function onBlur(value) {
        if (value !== "") {
            if (password.newPassword !== password.checkPassword) {
                setValidate(true);
                actions.validPassword(true);
            } else {
                setValidate(false);
                actions.validPassword(false);
                actions.cleanInvalid("newPassword");
                actions.cleanInvalid("checkPassword");
            }
        }
    }

    return (
        <div>
            <Modal style={{ marginTop: "100px" }} className="text-center" isOpen={isOpen} toggle={() => { toggle(); actions.cleanRegister() }}>
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Alteração de Senha</h5>
                </Navbar>
                <ModalBody>
                    {invalid.currentPassword ? <Label style={{ color: "red" }}>Senha atual não pode estar em branco</Label> : ""}
                    {(invalid.newPassword && password.newPassword === "") ? <Label style={{ color: "red" }}>Nova senha não pode estar em branco</Label> : ""}
                    {(invalid.checkPassword && password.checkPassword === "") ? <Label style={{ color: "red" }}>Confirmação da senha não pode estar em branco</Label> : ""}
                    {((password.currentPassword === password.newPassword) && (password.currentPassword !== "" && password.newPassword !== "")) ? <Label style={{ color: "red" }}>Nova senha não pode ser igual a anterior</Label> : ""}
                    <Form>
                        <FormGroup >
                            <Col className="text-left ml-auto mr-auto" sm={10}>
                                <InputPassword
                                    type="password"
                                    name="senha"
                                    id="senha"
                                    placeholder="Digite aqui..."
                                    label="Senha Atual"
                                    value={password.currentPassword}
                                    onChange={e => onChange(e.target.value, "currentPassword")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    isInvalid={invalid.currentPassword}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup >
                            <Col className="text-left ml-auto mr-auto" sm={10}>
                                <InputPassword
                                    type="password"
                                    name="senha"
                                    id="senha"
                                    placeholder="Digite aqui..."
                                    label="Nova Senha"
                                    value={password.newPassword}
                                    onChange={e => onChange(e.target.value, "newPassword")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    onBlur={e => onBlur(e.target.value)}
                                    isInvalid={invalid.newPassword}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup >
                            <Col className="text-left ml-auto mr-auto" sm={10}>
                                <InputPassword
                                    type="password"
                                    name="senha"
                                    id="senha"
                                    placeholder="Digite aqui..."
                                    label="Confirmar Nova Senha"
                                    value={password.checkPassword}
                                    onChange={e => onChange(e.target.value, "checkPassword")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    onBlur={e => onBlur(e.target.value)}
                                    isInvalid={invalid.checkPassword}
                                />
                            </Col>
                        </FormGroup>
                        {(password.newPassword && password.checkPassword) !== "" ?

                            validatePassword ? <div style={{ fontSize: '177%', color: 'red' }} >
                                <CgLock style={{ fontSize: '90%' }} />
                                <Label style={{ marginLeft: '2%' }}>Senhas não compatíveis </Label>
                            </div>
                                :
                                <div style={{ fontSize: '177%', color: 'green' }} >

                                    <CgLockUnlock style={{ fontSize: '90%' }} />
                                    <Label style={{ marginLeft: '2%' }}>Senhas compatíveis </Label>

                                </div>
                            : ""}
                    </Form>
                </ModalBody>
                <div className="m-3">
                    <Row>
                        <Col>
                            <Button
                                onClick={() => { toggle(); actions.cleanRegister() }}
                                className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px"
                                }}
                            >
                                Cancelar
                                </Button>

                            <Button
                                onClick={() => controller.update(password, validPassword)}
                                color="danger"
                                className="purpleButton "
                                style={{ width: "150px" }}
                            >
                                Salvar
                                </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
}

export default ModalSenha;