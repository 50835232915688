import history from "../util/history";
import api from "../util/api";
import { ValidateEmail } from "../util/validations";
import * as loginActions from "../store/modules/login/actions";
import {
  notifyInfo,
  notifyInfoPassword,
  notifyWarn,
} from "../components/message/message";
import { isAuthenticated } from "../util/auth";
import { cripto } from "cripto";
import { telasSistema } from "../util/telas";
import { currentRoutes } from "../routes";

export async function LogIn(login) {
  if (!ValidateEmail(login.email) || login.email === "") {
    loginActions.invalidLogin("email");
    notifyWarn("Email inválido");
    return;
  }

  if (login.password === "") {
    loginActions.invalidLogin("password");
    notifyWarn("Senha não pode estar em branco");
    return;
  }

  const body = {
    email: login.email,
    senha: login.password,
  };

  loginActions.setLoadingLoagin(true);
  const data = await api.post("autenticacao/logar", body);
  loginActions.setLoadingLoagin(false);

  if (!data.success) {
    return;
  }

  localStorage.setItem("TOKEN_KEY", data.content.token);
  localStorage.setItem("id", data.content.id);
  localStorage.setItem(
    "userControllkeys",
    cripto.toCode(JSON.stringify(data.content.telas))
  );
  loginActions.setUserControllKeys(data.content.telas);
  currentRoutes(data.content.telas);
  await telasPermitions(data.content.telas);

  if (localStorage.getItem("credentials")) {
    localStorage.removeItem("credentials");
  }

  const credentials = login.saveCredentials
    ? {
        checked: login.saveCredentials,
        email: login.email,
      }
    : { checked: login.saveCredentials };

  localStorage.setItem("credentials", JSON.stringify(credentials));

  isAuthenticated() ? history.push("/") : history.push("/login");
  loginActions.cleanAll();
}

async function telasPermitions(telas) {
  telas.forEach((tela) => {
    telasSistema.forEach((telaSistema) => {
      if (telaSistema.id === tela.id.toUpperCase()) {
        var permitions = {
          visualizar: true,
        };
        tela.permissoes.forEach((permition) => {
          if (
            permition.id.toUpperCase() ===
            "2A4A690A-01D2-4364-ABA4-6B2D977A3442"
          ) {
            permitions.criar = true;
          }
          if (
            permition.id.toUpperCase() ===
            "A02B98DB-5DD3-4803-91B6-CDE757D86C00"
          ) {
            permitions.deletar = true;
          }
          if (
            permition.id.toUpperCase() ===
            "D4BB230A-9583-4A04-9583-1A8B5D14F750"
          ) {
            permitions.autorizar = true;
          }
          if (
            permition.id.toUpperCase() ===
            "4EC5FA97-45ED-4659-901A-F50DCC4F5575"
          ) {
            permitions.filtros = true;
          }
          if (
            permition.id.toUpperCase() ===
            "1702FE06-7E33-48EE-BE6C-0B96B5209ACA"
          ) {
            permitions.estornar = true;
          }
          if (
            permition.id.toUpperCase() ===
            "CDDB1F17-A507-461C-A004-5B040EA330C9"
          ) {
            permitions.desbloquear = true;
          }
        });
        telaSistema.actions(permitions);
      }
    });
  });
}

export async function requestEmail(email) {
  if (!email) {
    loginActions.invalidLogin("email");
    return notifyWarn("Informe um e-mail");
  }
  if (!ValidateEmail(email)) {
    loginActions.invalidLogin("email");
    return notifyWarn("Informe um e-mail válido");
  }

  const body = {
    email,
  };

  loginActions.setLoadingLoagin(true);
  const data = await api.post(`Autenticacao/solicitacao-recuperar-senha`, body);
  loginActions.setLoadingLoagin(false);

  if (!data.success) {
    return;
  }

  loginActions.setRequest(true);
  return notifyInfoPassword(
    "O e-mail para redefinição de senha será enviado para o e-mail informado caso seja de algum usuário pré-cadastrado"
  );
}

export async function getPermitions() {
  loginActions.setLoadingLoagin(true);
  const data = await api.get(`Autenticacao/permissoes`);
  loginActions.setLoadingLoagin(false);

  if (!data.success) {
    return;
  }

  telasPermitions(data.content.telas);
  loginActions.setUserControllKeys(data.content.telas);
  return { email: data.content.email, nome: data.content.nome };
}
