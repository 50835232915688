import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export function MultiSelect(props = { options: [] }) {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first });
    }

    if (props.options !== undefined) {
      props.options.forEach((option) => {
        if (props.select) {
          obj.push({
            value: option[props.select.value],
            label: option[props.select.label],
          });
        } else {
          obj.push({
            value: option.value,
            label: option.label,
          });
        }
      });
      setOptions(obj);
      setSelected(props.options.filter((option) => option.value === "0"));
    }
  }, [props.first, props.options, props.select]);

  useEffect(() => {
    console.log(Array.isArray(props.value));
    if (options.length > 0 && Array.isArray(props.value.value)) {
      const selectedOptions = options.filter((option) =>
        props.value.value.some(
          (val) => val === option.value || val === option.label
        )
      );
      setSelected(selectedOptions);
    }
  }, [options, props.value]);

  const handleChange = (value) => {
    const addedOption = value.find(
      (v) => !selected.some((s) => s.value === v.value)
    );
    const removedOption = selected.find(
      (s) => !value.some((v) => v.value === s.value)
    );

    if (addedOption) {
      props.onAdd?.(addedOption);
    }

    if (removedOption) {
      props.onRemove?.(removedOption);
    }

    setSelected(value);
    props.onChange?.(value);
  };

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={handleChange}
      noOptionsMessage={() => "Não encontrado"}
      isDisabled={props.disabled}
      isMulti
      isClearable={false}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: props.invalid ? "#ff0000" : "#d3d3d3",
          color: props.invalid ? "#ff0000" : "#000000",
          minHeight: "35px",
        }),
      }}
    />
  );
}
