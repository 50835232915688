import moment from "moment";
import classNames from "classnames";

export const COLUMNS = [
  {
    Header: "Nº da proposta",
    accessor: "proposta",
  },
  {
    Header: "Data de entrada",
    accessor: "dataEntrada",
    Cell: ({ value }) => {
      return moment(value).format("DD/MM/YYYY");
    },
    sortType: "datetime",
  },
  {
    Header: "Data Status",
    accessor: "dataStatus",
    Cell: ({ value }) => {
      return moment(value).format("DD/MM/YYYY");
    },
    sortType: "datetime",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Instituição Financeira",
    accessor: "instituicao",
  },
  {
    Header: "Convênio",
    accessor: "convenio",
  },
  {
    Header: "Produto",
    accessor: "produto",
  },
  {
    Header: "Tipo de operação",
    accessor: "tipoOperacao",
  },
  {
    Header: "Nome do cliente",
    accessor: "nomeCliente",
  },

  {
    Header: "CPF",
    accessor: "cpfCliente",
  },
  {
    Header: "Base de cálculo",
    accessor: "valorFinanciado",
  },
];
export const COLUMNS_COMISSOES = [
  {
    Header: "Tipo Comissão",
    accessor: "tipoComissao",
  },
  {
    Header: "Descrição",
    accessor: "descricao",
  },
  {
    Header: "Valor Bruto",
    accessor: "valorBruto",
    Cell: ({ value }) => {
      const isNegative = value.includes("-");
      return (
        <span className={classNames(isNegative && "text-danger")}>{value}</span>
      );
    },
  },
  {
    Header: "Valor Base",
    accessor: "valorBase",
  },
  {
    Header: "Loja Arrecadadora",
    accessor: "loja",
  },
  {
    Header: "Parcelas Recebidas",
    accessor: "parcelas",
  },
  {
    Header: "Arquivo",
    accessor: "arquivoImportado",
  },
  {
    Header: "Data Agendamento",
    accessor: "dataAgendamento",
    Cell: ({ value }) => {
      if (!value) {
        console.error("Valor da data não encontrado:", value);
        return "";
      }

      const parts = value.split("/");
      if (parts.length !== 3) {
        console.error("Formato da data inválido:", value);
        return "";
      }
      const [day, month, year] = parts;
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    sortType: (rowA, rowB) => {
      const [dayA, monthA, yearA] = rowA.values.dataAgendamento
        .split("/")
        .map(Number);
      const [dayB, monthB, yearB] = rowB.values.dataAgendamento
        .split("/")
        .map(Number);

      const dateA = new Date(yearA, monthA - 1, dayA);
      const dateB = new Date(yearB, monthB - 1, dayB);

      return dateA - dateB; 
    },
  },
];

export const COLUMNS_CONTAS = [
  {
    Header: "Loja",
    accessor: "loja",
  },
  {
    Header: "Master",
    accessor: "master",
  },
  {
    Header: "Percentual",
    accessor: "percentual",
  },
  {
    Header: "Valor",
    accessor: "valor",
  },
];

export const COLUMNS_CP = [
  {
    Header: "Recebedor",
    accessor: "recebedor",
  },
  {
    Header: "Hierarquia",
    accessor: "hierarquia",
  },
  {
    Header: "Valor Pago",
    accessor: "valorPago",
  },
  {
    Header: "Campanha",
    accessor: "campanha",
  },
  {
    Header: "Regra",
    accessor: "regra",
  },
  {
    Header: "Data Vencimento",
    accessor: "dataVencimento",
  },
];

export const COLUMNS_LOG = [
  {
    Header: "Data Criação Log",
    accessor: "dataCriacao",
  },
  {
    Header: "Codigo Error",
    accessor: "codigoError",
  },
  {
    Header: "Mensagem",
    accessor: "mensagem",
  },
  {
    Header: "Http Status Code",
    accessor: "httpStatusCode",
  },
  {
    Header: "Status Code",
    accessor: "statusCode",
  },
  {
    Header: "Log Campos Error",
    accessor: "logCamposError",
  },
];
