import React from "react";

import { maskDate} from "../../../../util/masks";

import "./style.css";

export default function ({ data }) {
  return (
    <div style={{ overflow: "auto" }}>
      <table style={{ marginTop: "30px" }}>
        <thead>
          <tr>
            <th className="colunaCampanha">Regra</th>
            <th className="colunaCampanha">Ranking</th>
            <th className="colunaCampanha">Meta </th>
            <th className="colunaCampanha">Meta DU </th>
            <th className="colunaCampanha">Elegível </th>
            <th className="colunaCampanha">Produção</th>
            <th className="colunaCampanha">% atingido </th>
            <th className="colunaCampanha">Meta Restante</th>
            <th className="colunaCampanha">Meta DU Recalc.</th>
            <th className="colunaCampanha">Produção Projetada </th>
            <th className="colunaCampanha">Faixa </th>
            <th className="colunaCampanha">Projeção de ganho </th>
            <th className="colunaCampanha">Data Início </th>
            <th className="colunaCampanha">Data Fim </th>
            <th className="colunaCampanha">Data Apuração </th>
          </tr>
        </thead>
        <tbody>
          {data.map((e, i) => {
            return <FirstLevelTableRow data={e} index={i} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

function FirstLevelTableRow({ data, index }) {
    return (
      <tr key={index} className="tr">
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", width: "300px", textAlign: "center" }}
        >
          {data.descricaoCampanha}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {data.posicaoGeral}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.meta).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.metaDu).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {data.metaBatida ? "Sim" : "Não"}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.producaoMes).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.incremento).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.metaRestante).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.metaDuRecalculada).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.valorProjetado).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {data.faixa}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {Math.round(data.valorProjecao).toLocaleString()}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {maskDate(data.dataInicio, "DD/MM/YYYY")}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {maskDate(data.dataFim, "DD/MM/YYYY")}
        </td>
        <td
          className="linhaGreen"
          style={{ background: "#F8F8FF", textAlign: "center" }}
        >
          {maskDate(data.dataExecucao, "DD/MM/YYYY")}
        </td>
      </tr>
    );
  }
  