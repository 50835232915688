import api from "../util/api";
import * as actions from "../store/modules/aceiteCp/actions";
import { setLoading } from "../store/modules/globalLoading/actions";
import { maskMoeda, maskDate } from "../util/masks";
import { notifySuccess } from "../components/message/message";


const tipoCampanhaActions = {
  1: () => actions.enableCheckboxes("contaPagarStatusIntegracaoProdutividadeId"),
  2: () => actions.enableCheckboxes("contaPagarStatusIntegracaoSuperacaoId"),
  3: () => actions.enableCheckboxes("contaPagarStatusIntegracaoRepasseId"),
};

const tipoRegraSelected = {
  1: "3EF7CAA0-AF73-440C-801B-2B5788E69C37",
  2: "BE21DBC5-6C12-47B0-8C02-3D92111B3379",
  3: "D139F68F-6E22-46E4-B5D4-268D31FC9ECC",
};


export async function get(page, pageSize, search) {
  var url = `CampanhaPremiacaoContaPagar/conta-pagar?pagina=${page}&registros=${pageSize}
    `;

  if (search.campanhaId !== "0") {
    url = url + `&campanhaId=${search.campanhaId}`;
  }

  if (search.hierarquiaId !== "0") {
    url = url + `&hierarquiaId=${search.hierarquiaId}`;
  }

  if (search.pessoaId !== "0") {
    url = url + `&personaId=${search.pessoaId}`;
  }

  if (search.centroCustoId !== "0") {
    url = url + `&ccustoId=${search.centroCustoId}`;
  }

  if (search.empresaFuncionarioId !== "0") {
    url = url + `&empresaFuncionarioId=${search.empresaFuncionarioId}`;
  }

  if (search.campanhaTipo !== "0") {
    url = url + `&campanhaTipo=${search.campanhaTipo}`;
    if (tipoCampanhaActions[Number(search.campanhaTipo)]) {
      tipoCampanhaActions[Number(search.campanhaTipo)]();
      actions.setModalEdicaoField(tipoRegraSelected[Number(search.campanhaTipo)], "tipoRegraId");
    }
  } else {
    actions.enableCheckboxes("")
    actions.setModalEdicaoField("", "tipoRegraId");
  }

  if (search.statusIntegracao !== "0") {
    url = url + `&statusIntegracaoId=${search.statusIntegracao}`;
  }

  if (search.sistema !== "0") {
    url = url + `&sistema=${search.sistema}`;
  }

  if (search.dataPagamento !== "0") {
    url = url + `&dataPagto=${search.dataPagamento}`;
  }

  if (search.dataIntegracao !== "0") {
    url = url + `&dataIntegracao=${search.dataIntegracao}`;
  }

  if (search.statusFavorecido !== "" && search.statusFavorecido !== "0") {
    const isIntegrated = !!search.statusFavorecido;
    url = url + `&statusFavorecido=${isIntegrated}`;
  }

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content.registros.forEach((item) => {
    item.percAtingimentoSobreMeta = maskMoeda(
      Number(item.percAtingimentoSobreMeta).toFixed(2)
    );
    item.producaoTotal = maskMoeda(Number(item.producaoTotal).toFixed(2));
    item.receitaTotal = maskMoeda(Number(item.receitaTotal).toFixed(2));
    item.percReceitaTotal = maskMoeda(Number(item.percReceitaTotal).toFixed(2));
    item.valorComissaoPagaOriginal = maskMoeda(
      Number(item.valorComissaoPagaOriginal).toFixed(2)
    );
    item.percComissaoPaga = maskMoeda(Number(item.percComissaoPaga).toFixed(2));
    item.valorProdutividadeOriginal = maskMoeda(
      Number(item.valorProdutividadeOriginal).toFixed(2)
    );
    item.percPremioPagoSuperacao = maskMoeda(Number(item.percPremioPagoSuperacao).toFixed(2));
    item.valorSuperacaoOriginal = maskMoeda(
      Number(item.valorSuperacaoOriginal).toFixed(2)
    );
    item.premioPago = maskMoeda(Number(item.premioPago).toFixed(2));
    item.percPremioPago = maskMoeda(Number(item.percPremioPago).toFixed(2));
    item.totalPago = maskMoeda(Number(item.totalPago).toFixed(2));
    item.metaTotal = maskMoeda(Number(item.metaTotal).toFixed(2));
    item.percTotalPagoSobreProducao = maskMoeda(
      Number(item.percTotalPagoSobreProducao).toFixed(2)
    );
    item.percTotalPagoSobreReceita = item.percTotalPagoSobreReceita.toFixed(2);
    item.percPremioPagoProdutividade = item.percPremioPagoProdutividade.toFixed(2);
    item.dataEmissao = maskDate(item.dataEmissao, "DD/MM/YYYY");
    item.dataVencimento = maskDate(item.dataVencimento, "DD/MM/YYYY");
    item.statusFavorecido =
      item.statusFavorecido === 1 ? "Admitido" : "Demitido";
    item.aceito =
      item.statusProdutividadeId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1" &&
      item.statusComissaoPagaId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1" &&
      item.statusSuperacaoId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1"
        ? true
        : false;
    item.isSelected = false;
  });
  actions.setSelectedItems([]);
  actions.setItems(data.content.registros);
  actions.getAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getCanais() {
  const data = await api.get(`canal`);

  if (!data.success) {
    return;
  }
  actions.setCanais(data.content);
}
export async function getCampanhas(canalId) {
  try {
    let url = "";

    if (canalId) {
      url = `CampanhaPremiacao/campanhas-processadas?canalId=${canalId}`;
    } else {
      actions.setCampanhas([]);
      return;
    }

    const response = await api.get(url);

    if (response.success) {
      actions.setCampanhas(response.content);
    } else {
      console.error("Erro ao obter campanhas:", response.error);
    }
  } catch (error) {
    console.error("Erro ao obter campanhas:", error);
  }
}

export async function getHierarquia(canalId, campanhaId) {
  let url = `Hierarquias/hierarquia-canal/${canalId}`;

  if (campanhaId) {
    url = `CampanhaPremiacaoContaPagar/campanha-hierarquias?campanhaId=${campanhaId}`;
  }

  const data = await api.get(url);

  if (!data.success) {
    return;
  }
  actions.setHierarquias(data.content);
}

export async function getPessoas(pesquisa, page, pageSize) {
  var url = `Pessoa/?pagina=${page}&registros=${pageSize}&papel=2`;

  if (pesquisa.nome !== "") {
    url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=2`;
  }

  if (pesquisa.cpf !== "") url = url + `&cpfCnpj=${pesquisa.cpf}`;
  if (pesquisa.canalId !== "") url = url + `&canalId=${pesquisa.cpf}`;

  const data = await api.get(url);

  if (!data.success) {
    return;
  }

  actions.setGetAllConsultores({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
  });
  actions.setConsultores(data.content.registros);
}

export async function getCentrosCusto(canalId, campanhaId) {
  try {
    let url = "";

    if (!campanhaId) {
      actions.setCentrosCusto([]);
      return;
    }

    url = `CampanhaPremiacao/${campanhaId}/centros-custo`;

    const response = await api.get(url);

    if (response.success) {
      actions.setCentrosCusto(response.content);
    } else {
      console.error("Erro ao obter centros de custo:", response.error);
    }
  } catch (error) {
    console.error("Erro ao obter centros de custo:", error);
  }
}
export async function getRazaoSocialEmpresa() {
  const data = await api.get(`EmpresaFuncionario`);

  if (!data.success) {
    return;
  }
  actions.setEmpresasFuncionario(data.content);
}

export async function getStatus() {
  const data = await api.get(`contas-pagar/status`);

  if (!data.success) {
    return;
  }
  actions.setStatusIntegracao(data.content);
}

export async function getStatusFavorecido() {
  const data = await api.get(
    `CampanhaPremiacaoContaPagar/conta-pagar/statusFavorecido`
  );

  if (!data.success) {
    return;
  }
  actions.setStatusFavorecido(data.content);
}

export async function getSistemas() {
  const data = await api.get(`contas-pagar/sistemas`);

  if (!data.success) {
    return;
  }
  actions.setSistemas(data.content);
}

export async function aceite(conta) {
  setLoading(true);
  const data = await api.put(`CampanhaPremiacaoContaPagar/aceite`, {
    ids: conta.ids,
    campanhaPremiacaoTipoRegraId: conta.campanhaPremiacaoTipoRegraId,
    campanhaPremiacaoSistemaIntegracaoId:
      conta.campanhaPremiacaoSistemaIntegracaoId,
  });
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Itens aceitos com sucesso!");
  return true;
}