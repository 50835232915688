import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { COLUMNS_COUNT_ORCADO } from "../constants/columns"; 
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import { maskDate } from "../../../util/masks";
import * as actions from "../../../store/modules/centroCusto/actions";
import * as controller from "../../../controllers/centroCustoController";
import { useSelector } from "react-redux";

function TableHeadcountOrcado() {
  const { seeRegister, item } = useSelector((state) => state.centroCusto);
  const columns = useMemo(() => COLUMNS_COUNT_ORCADO, []);
  
  // Transformar datas em objetos Date para permitir ordenação correta
  const data = useMemo(() => {
    return item.headCountOrcadoLojasCentroCusto.map(entry => ({
      ...entry,
      dataInicial: entry.dataInicial ? new Date(entry.dataInicial) : null,
      dataFinal: entry.dataFinal ? new Date(entry.dataFinal) : null,
    }));
  }, [item.headCountOrcadoLojasCentroCusto]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  async function handleRemoverItem(row, index) {
    let valid = await controller.deleteHeadcountOrcado(row.id);

    if (valid) {
      const arrHeadCounts = [...data]; 
      arrHeadCounts.splice(index, 1); 
      actions.item({ ...item, headCountOrcadoLojasCentroCusto: arrHeadCounts }); 
    }
  }

  async function handleUpdate(row) {
    actions.headcount({
      headCountOrcadoLojasCentroCusto: row.qtde,
      dataInicio: maskDate(row.dataInicial, "YYYY-MM-DD"),
      dataFim: maskDate(row.dataFinal, "YYYY-MM-DD"),
      id: row.id,
    });
  }

  return (
    <table {...getTableProps()} style={{ marginTop: "20px" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id}>
                {column.render("Header")}
                <span className="float-right">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <BsIcons.BsArrowDown
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            title: undefined,
                          })
                        )}
                      />
                    ) : (
                      <BsIcons.BsArrowUp
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            title: undefined,
                          })
                        )}
                      />
                    )
                  ) : (
                    <BsIcons.BsArrowDownUp
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          title: undefined,
                        })
                      )}
                    />
                  )}
                </span>
              </th>
            ))}
            <th style={{ textAlign: "center" }}>Ações</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>
                  {cell.column.id === "dataInicial" || cell.column.id === "dataFinal"
                    ? maskDate(cell.value, "DD/MM/YYYY")
                    : cell.render("Cell")}
                </td>
              ))}
              <td style={{ textAlign: "center", width: "60px" }}>
                {!seeRegister && (
                  <>
                    <BiIcons.BiEdit
                      style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#C4C7CA",
                        marginRight: "7px",
                      }}
                      onClick={() => handleUpdate(row.original)}
                    />
                    <BiIcons.BiTrash
                      style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#C4C7CA",
                      }}
                      onClick={() => handleRemoverItem(row.original, row.index)}
                    />
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableHeadcountOrcado;
