import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import "../../style.css";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as controller from "../../controllers/comissaoController";
import * as actions from "../../store/modules/comissao/actions";
import Breadcrumb from "../../components/breadcrumbs";

export default function Comissao() {
  const {
    registros,
    copia,
    activeTab,
    seeRegister,
    eventos,
    permitions,
    pesquisar,
  } = useSelector((state) => state.comissao);

  useEffect(() => {
    if (window.location.search !== "") {
      actions.setActiveTab("Register");
    } else {
      actions.setActiveTab("Search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function saveItem() {
    if (registros.id === "") {
      controller.create(registros, eventos);
      actions.cleanEventos(true);
    } else {
      controller.update(registros, eventos, copia);
      actions.cleanEventos(true);
    }
  }
  function changeTab(tab) {
    actions.setActiveTab(tab);
    actions.cleanRegister();
  }

  function seeRegisterF() {
    if (registros.id !== "") {
      actions.cleanRegister();
    }
    actions.setSeeRegister(false);
    actions.cleanEventos(true);
  }

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            {activeTab === "Search" ? (
              permitions.criar && (
                <Col md={12}>
                  <Row className="pt-2 pb-2">
                    <Col md={8}>
                      <Button
                        onClick={() => changeTab("Register")}
                        style={{ width: "210px" }}
                        className="purpleButton"
                      >
                        Cadastrar Tabela de Comissão
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <Col className="pt-2 pb-2">
                <Button
                  onClick={() => {
                    changeTab("Search");
                    seeRegisterF();
                  }}
                  style={{ width: "150px", color: "white" }}
                  className="purpleButton"
                >
                  <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                  Voltar
                </Button>
                {!seeRegister && (
                  <Button
                    onClick={() => saveItem()}
                    style={{ width: "242px", float: "right" }}
                    className="purpleButton"
                  >
                    Salvar Cadastro
                  </Button>
                )}
              </Col>
            )}
          </Form>
        </Card>
      </Col>

      <TabContent style={{ width: "100%" }} activeTab={activeTab}>
        <TabPane tabId="Search">
          <br />
          <Search />
        </TabPane>
        <TabPane tabId="Register">
          <br />
          <Register />
        </TabPane>
      </TabContent>
    </>
  );
}
