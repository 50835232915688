import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, TabContent, TabPane } from "reactstrap";
import * as actions from "../../store/modules/usuario/actions";
import Search from "./tabs/search";
import Register from "./tabs/register";
import * as usuarioControllers from "../../controllers/usuarioController";
import Breadcrumb from "../../components/breadcrumbs";
const Usuario = () => {
  const {
    item,
    copy,
    activeTab,
    seeRegister,
    verifyPass,
    changePass,
    passwords,
    permitions,
  } = useSelector((state) => state.usuario);

  useEffect(() => {
    actions.setActiveTab("Search");
  }, []);

  function changeTab(tab) {
    actions.setActiveTab(tab);
    actions.cleanRegister();
  }
  function seeRegisterF() {
    if (item.id !== "") {
      actions.cleanRegister();
    }
    actions.setSeeRegister(false);
  }

  async function handleVoltar() {
    changeTab("Search");
    seeRegisterF();
    usuarioControllers.getAll(0, 10, {
      cpf: "",
      email: "",
      nome: "",
    });
    actions.cleanSearch();
  }

  async function saveItem() {
    let hasSuccessed = false;

    if (item.id !== "") {
      if (changePass && JSON.stringify(item) === JSON.stringify(copy)) {
        hasSuccessed = await usuarioControllers.updatePass(
          item.id,
          passwords,
          verifyPass
        );
      } else if (JSON.stringify(item) !== JSON.stringify(copy)) {
        hasSuccessed = await usuarioControllers.update(item, copy);

        if (hasSuccessed && changePass) {
          hasSuccessed = await usuarioControllers.updatePass(
            item.id,
            passwords,
            verifyPass
          );
        }
      }
    } else {
      hasSuccessed = await usuarioControllers.create(
        item,
        passwords,
        verifyPass
      );
    }

    if (hasSuccessed) {
      actions.setActiveTab("Search");
      actions.cleanSearch();
    }
  }

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />

        <Card>
          <Form>
            {activeTab === "Search" ? (
              permitions.criar && (
                <Col md={12}>
                  <Row className="pt-2 pb-2">
                    <Col md={8}>
                      <Button
                        onClick={() => changeTab("Register")}
                        style={{ width: "210px" }}
                        className="purpleButton"
                      >
                        Cadastrar Usuário
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <Col className="pt-2 pb-2">
                <Button
                  onClick={() => {
                    handleVoltar();
                  }}
                  style={{ width: "150px", color: "white" }}
                  className="purpleButton"
                >
                  <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                  Voltar
                </Button>
                {!seeRegister && (
                  <Button
                    onClick={saveItem}
                    style={{ width: "242px", float: "right" }}
                    className="purpleButton"
                  >
                    Salvar Cadastro
                  </Button>
                )}
              </Col>
            )}
          </Form>
        </Card>
      </Col>

      <TabContent style={{ width: "100%" }} activeTab={activeTab}>
        <TabPane tabId="Search">
          <br />
          <Search />
        </TabPane>
        <TabPane tabId="Register">
          <br />
          <Register />
        </TabPane>
      </TabContent>
    </>
  );
};
export default Usuario;
