export const COLUMNS = [
    {
        Header: "Favorecido",
        accessor: "nomePessoa"
    },
    {
        Header: "Hierarquia",
        accessor: "dsHierarquia"
    },
    {
        Header: "Status do Favorecido",
        accessor: "statusFavorecido"
    },
    {
        Header: "Meta Total",
        accessor: "metaTotal",
    },
    {
        Header: "Produção Total $",
        accessor: "producaoTotal"
    },
    {
        Header: "Atingimento s/ meta %",
        accessor: "percAtingimentoSobreMeta"
    },
    {
        Header: "Total Pago $",
        accessor: "totalPago"
    },
    {
        Header: "Total Pago s/ Produção %",
        accessor: "percTotalPagoSobreProducao"
    },
    {
        Header: "Total Pago s/ Receita %",
        accessor: "percTotalPagoSobreReceita"
    },
    {
        Header: "Receita Total $",
        accessor: "receitaTotal"
    },
    {
        Header: "Receita Total %",
        accessor: "percReceitaTotal"
    },
    {
        Header: "Repasse de Comissão $",
        accessor: "valorComissaoPagaOriginal"
    },
    {
        Header: "Repasse de Comissão %",
        accessor: "percComissaoPaga"
    },
    {
        Header: "Status Comissão",
        accessor: "contaPagarStatusIntegracaoRepasse"
    },
    {
        Header: "Prêmio Superação $",
        accessor: "valorSuperacaoOriginal"
    },
    {
        Header: "Prêmio Superação %",
        accessor: "percPremioPagoSuperacao"
    },
    {
        Header: "Status Superação",
        accessor: "contaPagarStatusIntegracaoSuperacao"
    },
    {
        Header: "Prêmio Produtividade $",
        accessor: "valorProdutividadeOriginal"
    },
    {
        Header: "Prêmio Produtividade %",
        accessor: "percPremioPagoProdutividade"
    },
    {
        Header: "Status Produtividade",
        accessor: "contaPagarStatusIntegracaoProdutividade"
    },
    
]

